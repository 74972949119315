export default [
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard-analytics',
      component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    },
    // {
    //   path: '/dashboard/ecommerce',
    //   name: 'dashboard-ecommerce',
    //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    // },
    {
        path: '/apps/wa',
        name: 'wa-chat',
        component: () => import('@/views/apps/wa/Chat.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'chat-application',
        },
    },
    {
        path: '/apps/telegram',
        name: 'telegram-chat',
        component: () => import('@/views/apps/telegram/Chat.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'chat-application',
        },
    },
    {
        path: '/apps/email',
        name: 'apps-email',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'email-application',
        },
    },
    {
      path: '/apps/email/:folder',
      name: 'apps-email-folder',
      component: () => import('@/views/apps/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'apps-email',
      },
      beforeEnter(to, _, next) {
        if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/apps/email/label/:label',
      name: 'apps-email-label',
      component: () => import('@/views/apps/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'apps-email',
      },
      beforeEnter(to, _, next) {
        if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
        else next({ name: 'error-404' })
      },
    },
    {
        path: '/apps/all-message',
        name: 'all-message',
        component: () => import('@/views/apps/all-message/GoodTable.vue'),
        meta: {
          pageTitle: 'All Message',
        },
    },
    {
      path: '/apps/pengaduan',
      name: 'pengaduan',
      component: () => import('@/views/apps/pengaduan/GoodTable.vue'),
      meta: {
        pageTitle: 'Pengaduan',
      },
    },
    {
      path: '/apps/mapping',
      name: 'mapping',
      component: () => import('@/views/apps/mapping/GoodTable.vue'),
      meta: {
        pageTitle: 'Mapping',
      },
    },
    {
      path: '/apps/cluster',
      name: 'cluster',
      component: () => import('@/views/apps/cluster/GoodTable.vue'),
      meta: {
        pageTitle: 'Cluster',
      },
    },
    {
      path: '/apps/pengguna',
      name: 'pengguna',
      component: () => import('@/views/apps/pengguna/GoodTable.vue'),
      meta: {
        pageTitle: 'User',
      },
    },
    {
        path: '/charts-and-maps/charts/apex-chart',
        name: 'charts-apex-chart',
        component: () => import('@/views/charts-and-maps/charts/apex-chart/ApexChart.vue'),
        meta: {
          pageTitle: 'Apex Chart',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Apex Chart',
              active: true,
            },
          ],
        },
      },
      {
        path: '/charts-and-maps/charts/chartjs',
        name: 'charts-chartjs',
        component: () => import('@/views/charts-and-maps/charts/chartjs/Chartjs.vue'),
        meta: {
          pageTitle: 'Chartjs',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Chartjs',
              active: true,
            },
          ],
        },
      },
      {
        path: '/charts-and-maps/charts/echart',
        name: 'charts-echart',
        component: () => import('@/views/charts-and-maps/charts/echart/Echart.vue'),
        meta: {
          pageTitle: 'Echart',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Echart',
              active: true,
            },
          ],
        },
      },
    {
        path: '/apps/users/list',
        name: 'apps-users-list',
        component: () => import('@/views/apps/user/users-list/UsersList.vue'),
        meta: {
          pageTitle: 'User',
        },
    },
    {
      path: '/apps/users/hak-akses',
      name: 'apps-users-hak-akses',
      component: () => import('@/views/apps/user/users-hak-akses/UsersHakAkses.vue'),
      meta: {
        pageTitle: 'Hak Akses',
      },
    },
    {
        path: '/apps/users/view/:id',
        name: 'apps-users-view',
        component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    },
    {
        path: '/apps/users/edit/:id',
        name: 'apps-users-edit',
        component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    },

    {
      path: '/apps/profil/list',
      name: 'apps-profil-list',
      component: () => import('@/views/apps/profil/profil-list/ProfilList.vue'),
      meta: {
        pageTitle: 'User',
      },
    },

    {
      path: '/apps/profil/view/',
      name: 'apps-profil-view',
      component: () => import('@/views/apps/account-setting/AccountSetting.vue'),
    },
    {
        path: '/apps/profil/edit/:id',
        name: 'apps-profil-edit',
        component: () => import('@/views/apps/profil/profil-edit/ProfilEdit.vue'),
    },

  ]
  